<template>
  <b10-base>
    <v-card>
      <v-card-title
        class="title primary white--text"
      >
        <v-icon
          dark
          left
        >
          {{ $vuetify.icons.values.ronda }}
        </v-icon>Rondas
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          small
          @click.stop="clickVerRondas"
        >
          Rondas en curso
        </v-btn>
        <v-btn
          color="primary"
          text
          small
          @click.stop="clickRealizarRonda"
        >
          Realizar ronda
        </v-btn>
      </v-card-actions>
    </v-card>
  </b10-base>
</template>

<script>
export default {
  methods: {
    clickVerRondas () {
      this.$emit('click-ver-rondas')
    },
    clickRealizarRonda () {
      this.$emit('click-realizar-ronda')
    }
  }
}
</script>
