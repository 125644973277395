<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      persistent
      enable-resize-watcher
      fixed
      app
      color="grey lighten-3"
      width="270"
    >
      <main-menu
        :sync-pendientes="syncPendientes"
        :partes-descargados="partesDescargados.length"
        :usuario-permisos="usuario.permisos"
      />
    </v-navigation-drawer>
    <b10-toolbar
      :title="title"
      :back="false"
    >
      <v-app-bar-nav-icon
        slot="left"
        @click.stop="toggleDrawer"
      />
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-btn
        v-show="syncPendientes > 0 && network && network.online"
        large
        block
        color="red"
        :dark="!syncing"
        :disabled="syncing"
        @click.stop="sincronizar"
      >
        <v-icon
          left
        >
          {{ $vuetify.icons.values.sync }}
        </v-icon>
        Sincronizar {{ syncPendientes }} {{ 'cambio'|pluralize(syncPendientes, 's') }}
      </v-btn>
      <dashboard-presencia
        v-if="hasPerm(permissions.presencia.registrarEntrada) || hasPerm(permissions.presencia.registrarSalida)"
        class="mt-2"
        @click-update-presencia="clickUpdatePresencia"
      />
      <dashboard-ronda
        v-if="presencia.idpuestoServicio && appVigilantes"
        class="mt-2"
        @click-ver-rondas="clickVerRondas"
        @click-realizar-ronda="clickRealizarRonda"
      />
      <dashboard-novedad
        v-if="presencia.idpuestoServicio && appVigilantes"
        class="mt-2"
        @click-add-novedad="clickAddNovedad"
        @click-ver-novedad="clickVerNovedad"
      />

      <dashboard-cronometro
        v-if="cronometroFinicio"
        class="mt-2"
        :inicio="cronometroFinicio"
        :idparte="cronometroIdparteTrabajo"
        @click-cronometro-detener="clickCronometroDetener"
      />
      <dashboard-parte-trabajo
        v-if="partesDescargados.length > 0"
        class="mt-2"
        :items="partesDescargados"
        :max-items="3"
        @click-ver-todos="clickVerTodosParte"
        @click-item-list="clickItemListParte"
      />
      <b10-fab-button
        v-if="usuario.idtecnico && appTecnicos"
        :icon="$vuetify.icons.values.search"
        @click="clickBuscarMaterial"
      />
      <buscar-material-afectado-global
        :showing.sync="showingDialogs.buscarMaterialAfectado"
        :idtecnico="usuario.idtecnico"
        :has-perm-ver-asignados-otros="hasPermVerAsignadosOtros"
        :has-perm-ver-ordenes-pendientes="hasPermVerOrdenesPendientes"
      />
    </b10-page-content>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify'
import { basePageMixin } from '@/mixins/basePageMixin'
import { PARTE_TRABAJO, RONDA } from '@/utils/consts'
import Data from './IndexData'
import { doSync } from '@/sync/upload'
import MainMenu from './components/MainMenu'
import DashboardParteTrabajo from './components/DashboardParteTrabajo'
import DashboardCronometro from './components/DashboardCronometro'
import DashboardPresencia from './components/DashboardPresencia'
import DashboardRonda from './components/DashboardRonda'
import DashboardNovedad from './components/DashboardNovedad'
import BuscarMaterialAfectadoGlobal from './components/BuscarMaterialAfectadoGlobal'
import { fontColor } from '@/utils/ui'
import { encodeBase64 } from '@/utils/router'

export default {
  components: {
    MainMenu,
    DashboardParteTrabajo,
    DashboardCronometro,
    DashboardNovedad,
    DashboardPresencia,
    DashboardRonda,
    BuscarMaterialAfectadoGlobal,
  },
  mixins: [basePageMixin],
  data () {
    return {
      drawer: false,
      partesDescargados: [],
      operacionesPendientes: [],
      syncPendientes: 0,
      verDashboardOperaciones: false,
      showingDialogs: {
        buscarMaterialAfectado: false,
      },
      hasPermVerAsignadosOtros: false,
      hasPermVerOrdenesPendientes: false,
    }
  },
  computed: {
    cronometroFinicio: get('cronometro/finicio'),
    cronometroIdparteTrabajo: get('cronometro/idparte'),
    syncing: get('sync/syncing'),
    usuario: get('usuario'),
    network: get('network'),
    appComerciales: get('usuario/appComerciales'),
    appTecnicos: get('usuario/appTecnicos'),
    appVigilantes: get('usuario/appVigilantes'),
    presencia: sync('presencia'),
  },
  watch: {
    syncing (newSyncing) {
      // deja de sincronizar (autoSync) -> refrescar cambios pendientes
      if (!newSyncing) {
        this.updateSyncPendientes()
      }
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Beta10'
      if (this.usuario.idtecnico) {
        this.hasPermVerAsignadosOtros = this.hasPerm(this.permissions.partes.verAsignadosOtros)
        this.hasPermVerOrdenesPendientes = this.hasPerm(this.permissions.ordenes.verPendientes)
      }
      await this.cargarPartesDescargados()
      await this.updateSyncPendientes()
    },
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    async sincronizar () {
      if (!this.syncing) {
        try {
          await doSync(this.$offline, this.$api, this.$loading, this.$alert)
        } finally {
          await this.updateSyncPendientes()
        }
      } else {
        this.$alert.showSnackbarInfo('Ya está sincronizando los cambios')
      }
    },
    async cargarPartesDescargados () {
      const items = await Data.selectPartes(this, this.usuario.idtecnico)
      this.partesDescargados = this.formatPartesDescargados(items)
    },
    formatPartesDescargados (items) {
      for (let item of items) {
        item.title = this.$offline.parteTrabajo.title(item)
        item.subtitle = this.$offline.parteTrabajo.subtitle(item)
        if (item.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente) {
          item.avatarText = 'P'
          item.avatarColorBackground = PARTE_TRABAJO.colores.pendiente
          item.avatarColorText = fontColor(PARTE_TRABAJO.colores.pendiente)
        } else {
          item.avatarText = 'F'
          item.avatarColorBackground = PARTE_TRABAJO.colores.finalizado
          item.avatarColorText = fontColor(PARTE_TRABAJO.colores.finalizado)
        }
      }
      return items
    },
    clickItemListParte (item) {
      this.$appRouter.push({
        name: 'offline__parte-trabajo-view',
        params: {
          idparte_trabajo: item.parte_trabajo.idparte_trabajo
        }
      })
    },
    clickVerTodosParte () {
      this.$appRouter.push({
        name: 'offline__parte-descargados-list'
      })
    },
    clickCronometroDetener () {
      if (this.cronometroIdparteTrabajo) {
        this.$appRouter.push({
          name: 'offline__cronometro-detener'
        })
      } else {
        this.$appRouter.push({
          name: 'tiempos__cronometro-detener',
        })
      }
    },
    async updateSyncPendientes () {
      this.syncPendientes = await this.$offline.sync.syncCountPendiente()
    },
    clickVerRondas () {
      this.$appRouter.push({
        name: 'rondas__ronda-list',
        query: {
          _filter: encodeBase64({
            estado: {
              value: RONDA.estados.enCurso,
            },
          }),
        },
      })
    },
    clickRealizarRonda () {
      this.$appRouter.push({
        name: 'rondas__mronda-list',
      })
    },
    clickBuscarMaterial () {
      this.showingDialogs.buscarMaterialAfectado = !this.showingDialogs.buscarMaterialAfectado
    },
    async clickAddNovedad () {
      const parteNovedad = await this.$online.parteNovedad.getOrCreateParteNovedad(this.presencia.idpuestoServicio, this.presencia.idempresa)
      if (parteNovedad) {
        this.$appRouter.push({
          name: 'partesnovedad__lparte-novedad-add',
          params: {
            idparte_novedad: parteNovedad.idparte_novedad
          }
        })
      }
    },
    async clickVerNovedad () {
      this.$appRouter.push({
        name: 'puestosservicio__lparte-novedad-list',
        params: {
          idpuesto_servicio: this.presencia.idpuestoServicio,
        },
      })
    },
    async clickUpdatePresencia () {
      const presenciaResponse = await this.$online.presencia.selectPresenciaSinSalida(this.usuario.idempleado)
      if (presenciaResponse.data.result.dataset.length > 0) {
        const presencia = presenciaResponse.data.result.dataset[0]
        this.presencia.fentrada = presencia.fentrada.toISOString()
        this.presencia.descripcion = presencia.puesto_servicio_descripcion
        this.presencia.idpuestoServicio = presencia.idpuesto_servicio
        this.presencia.idparteNovedad = presencia.idparte_novedad
        this.presencia.idpuestoServicioServicio = presencia.idpuesto_servicio_servicio
        this.presencia.idempresa = presencia.idempresa
      } else {
        this.presencia.fentrada = null
        this.presencia.descripcion = null
        this.presencia.idpuestoServicio = null
        this.presencia.idparteNovedad = null
        this.presencia.idpuestoServicioServicio = null
        this.presencia.idempresa = null
      }
    }
  }
}
</script>
