<template>
  <v-bottom-sheet
    v-model="internalShowing"
  >
    <b10-qr-reader
      :showing.sync="showingQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
    <v-sheet
      height="100%"
      class="pa-3"
    >
      <v-form
        @submit.prevent="submit"
      >
        <v-text-field
          v-model="form.q"
          label="Buscar material afectado"
          :append-outer-icon="$vuetify.icons.values.qr"
          autofocus
          inputmode="numeric"
          @click:append-outer="clickQR"
        />
      </v-form>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import Data from './BuscarMaterialAfectadoGlobalData'
import _ from '@/utils/lodash'
import { encodeBase64 } from '@/utils/router'

export default {
  mixins: [internalShowingMixin],
  props: {
    idtecnico: {
      type: [Number, String],
      default: null,
    },
    hasPermVerAsignadosOtros: {
      type: Boolean,
      default: false,
    },
    hasPermVerOrdenesPendientes: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      formValido: true,
      form: {
        q: '',
      },
      showingQR: false,
    }
  },
  computed: {
  },
  watch: {
    showing (newValue) {
      if (newValue) {
        this.$set(this.form, 'q', '')
      }
    },
  },
  async created () {
  },
  methods: {
    submit () {
      if (this.form.q) {
        this.internalShowing = false
        this.search(this.form.q)
      }
    },
    clickQR () {
      this.internalShowing = false
      this.showingQR = !this.showingQR
    },
    async decodeQR (value) {
      this.search(value)
    },
    async buscarMaterialEnPartesPendientes(parteTrabajoMatsist, value) {
      const parteTrabajoTecnico = await Data.selectParteTrabajoTecnico(this, _.map(parteTrabajoMatsist, 'idparte_trabajo'))
      const parteAsignadoATecnico = _.filter(parteTrabajoTecnico.data.result.dataset, { 'idtecnico': this.idtecnico });
      if (parteAsignadoATecnico.length === 1) {
        // Si hay un único parte voy al parte pendiente
        this.$alert.showSnackbarInfo(`El material "${value}" se encuentra en éste parte, pero no está descargado.`)
        this.$appRouter.push({
          name: 'partes__parte-trabajo-view',
          params: {
            idparte_trabajo: parteAsignadoATecnico[0].idparte_trabajo
          }
        })
      } else if (parteAsignadoATecnico.length > 1) {
        // Si tiene más de un parte debería ir al listado, prefiltrando por esos partes
        const idsPartesAsignados = _.map(parteAsignadoATecnico, 'idparte_trabajo')
        this.$alert.showSnackbarInfo(`El material "${value}" se encuentra en varios partes asignados a ti.`)
        this.$appRouter.push({
          name: 'partes__parte-asignados-list',
          query: {
            _filter: encodeBase64({
              idparteTrabajo: {
                value: idsPartesAsignados,
              },
            }),
          },
        })
      } else {
        // Si no está en ningún parte asignado a mi y tengo permisos muestro esos partes
        if (this.hasPermVerAsignadosOtros) {
          const idsPartesAsignadosOtrosTecnicos = _.map(parteTrabajoTecnico.data.result.dataset, 'idparte_trabajo')
          const idsTecnicosDePartes = _.map(parteTrabajoTecnico.data.result.dataset, 'idtecnico')
          this.$alert.showSnackbarInfo(`El material "${value}" se encuentra en varios partes asignados a otros técnicos.`)
          this.$appRouter.push({
            name: 'partes__parte-asignados-list',
            query: {
              _filter: encodeBase64({
                idparteTrabajo: {
                  value: idsPartesAsignadosOtrosTecnicos,
                },tecnico: {
                  value: idsTecnicosDePartes,
                },
              }),
            },
          })
        }
      }
    },
    async buscarMaterialEnOrdenesPendientes(idsMaterialesSistema, value) {
      // tengo que buscarlo en OT's pendientes, siempre y cuando tenga permisos
      if (this.hasPermVerOrdenesPendientes) {
        const ordenTrabajoMatsist = await Data.selectOrdenTrabajoMatsist(this, idsMaterialesSistema)
        const idsOrdenesTrabajo = _.map(ordenTrabajoMatsist.data.result.dataset, 'idorden_trabajo')
        if (idsOrdenesTrabajo.length === 1) {
          this.$alert.showSnackbarInfo(`El material "${value}" se encuentra en ésta orden de trabajo sin asignar.`)
          this.$appRouter.push({
            name: 'ordenes__orden-trabajo-view',
            params: {
              idorden_trabajo: idsOrdenesTrabajo[0],
            },
          })
        } else if (idsOrdenesTrabajo.length > 1) {
          this.$alert.showSnackbarInfo(`El material "${value}" se encuentra en varias órdenes de trabajo sin asignar.`)
          this.$appRouter.push({
            name: 'ordenes__pendientes-orden-trabajo-list',
            query: {
              _filter: encodeBase64({
                idordenTrabajo: {
                  value: idsOrdenesTrabajo,
                },
              }),
            },
          })
        } else {
          this.$alert.showSnackbarError(`El material "${value}" no se encuentra en ninguna orden o parte pendiente.`)
        }
      }
    },
    async search (value) {
      // Busco primero en material afectado de partes que tenga descargado
      const material = await this.$offline.parteTrabajoMatsist.buscarMaterialPorCodigo(
        null, value, null, null
      )
      // Si hay una única coincidencia lo llevo al material afectado
      if (material.length === 1) {
        this.$appRouter.push({
          name: 'offline__material-afectado-view',
          params: {
            idparte_trabajo: material[0].parte_trabajo_matsist.idparte_trabajo,
            idparte_trabajo_matsist: material[0].parte_trabajo_matsist.idparte_trabajo_matsist,
          }
        })
      // Aunque pueda estar en más partes descargados, busco online por si está en partes sin descargar (y mostrar todos)
      } else {
        // Busco el idmaterial_sistema de ese nserie, puede ser que haya más de uno
        const materialSistema = await Data.selectMaterialSistemaPorNserie(this, value)
        // Busco en material afectado de partes
        if (materialSistema.data.result.dataset.length > 0) {
          const idsMaterialesSistema = _.map(materialSistema.data.result.dataset, 'idmaterial_sistema')
          const parteTrabajoMatsist = await Data.selectParteTrabajoMatsist(this, idsMaterialesSistema)
          if (parteTrabajoMatsist.data.result.dataset.length > 0) {
            this.buscarMaterialEnPartesPendientes(parteTrabajoMatsist.data.result.dataset, value)
          } else {
            this.buscarMaterialEnOrdenesPendientes(idsMaterialesSistema, value)
          }
        } else {
          this.$alert.showSnackbarError(`No se ha encontrado material número de serie, elemento o etiqueta "${value}".`)
        }
      }
    },
  },
}
</script>
