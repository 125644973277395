<template>
  <b10-base>
    <v-card>
      <v-card-title
        class="title primary white--text"
      >
        <v-icon
          dark
          left
        >
          {{ $vuetify.icons.values.ronda }}
        </v-icon>Novedades
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          small
          @click.stop="clickVerNovedad"
        >
          Ver novedades
        </v-btn>
        <v-btn
          color="primary"
          text
          small
          @click.stop="clickAddNovedad"
        >
          Añadir novedad
        </v-btn>
      </v-card-actions>
    </v-card>
  </b10-base>
</template>

<script>
export default {
  methods: {
    clickAddNovedad () {
      this.$emit('click-add-novedad')
    },
    clickVerNovedad () {
      this.$emit('click-ver-novedad')
    },
  }
}
</script>
