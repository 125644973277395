import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { PARTE_TRABAJO, CLASIFICACION_ESTADO_ORDEN_TRABAJO } from '@/utils/consts'

export default {
  async selectMaterialSistemaPorNserie (Vue, value) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addIsNull('fdesinstalacion', false)
    const apiFilterSearch = new APIFilter()
    apiFilterSearch
      .setOperator(API_FILTER_OPERATORS.OR)
      .addExact('nelemento', value)
      .addExact('nserie', value)
      .addExact('netiqueta', value)
      .addExact('esta_abonado', 0)
    apiFilter.addNestedFilter(apiFilterSearch)
    return await Vue.$api.call('materialSistema.select', { filter: apiFilter })
  },
  async selectParteTrabajoMatsist (Vue, idsMaterialSistema) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('estado_parte', PARTE_TRABAJO.estados.pendiente)
      .addIn('idmaterial_sistema', idsMaterialSistema)
    return await Vue.$api.call('parteTrabajoMatsist.select', { filter: apiFilter })
  },
  async selectParteTrabajoTecnico (Vue, pksParteTrabajo) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idparte_trabajo', pksParteTrabajo)
    const respParteTrabajoTecnico = await Vue.$api.call('parteTrabajoTecnico.select', { filter: apiFilter })
    return respParteTrabajoTecnico
  },
  async selectOrdenTrabajoMatsist (Vue, idsMaterialSistema) {
    const apiFilter = new APIFilter()
    apiFilter
      .addGT('estado', 0)
      .addExact('est_orden_clasificacion', CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente)
      .addIn('idmaterial_sistema', idsMaterialSistema)
    return await Vue.$api.call('ordenTrabajoMatsist.select', { filter: apiFilter })
  },
}